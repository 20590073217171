export default {
  computed: {
    hasConnnectionOptions () {
      return (Object.keys(this.connection.options).length > 0)
    },
    status () {
      return this.connection.status
    },
    integration () {
      const presenter = {
        label: 'Console'
      }
      if (this.connection.integration_type === 'edi') {
        presenter.label = 'EDI'
      } else if (this.connection.integration_type === 'storefront') {
        presenter.label = 'Storefront'
      } else if (this.connection.integration_type === 'api') {
        presenter.label = 'API'
      } else if (this.connection.integration_type === 'shopify') {
        presenter.label = 'Shopify'
      } else if (this.connection.integration_type === 'shipstation') {
        presenter.label = 'ShipStation'
      } else if (this.connection.integration_type === 'bigcommerce') {
        presenter.label = 'BigCommerce'
      } else if (this.connection.integration_type === 'woocommerce') {
        presenter.label = 'WooCommerce'
      }
      return presenter
    },
    netPriceMethod () {
      const presenter = {
        label: 'Specified on PO',
        tooltip: 'The retailer is responsible for tracking and passing item costs for each purchase order.'
      }
      if (this.connection.connection_fees.net_price_method === 'permit_cost') {
        presenter.label = 'Permit Cost'
        presenter.tooltip = 'Item cost is determined by the most recently accepted proposal.4'
      }
      return presenter
    }
  }
}
